import React, { useEffect, useState } from 'react';
import { XCircleIcon } from '@heroicons/react/24/outline';
import cn from 'classnames';

const statuses = {
    Complete: 'text-green-700 bg-green-50 ring-green-600/20',
    'In progress': 'text-gray-600 bg-gray-50 ring-gray-500/10',
    Archived: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
};

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


// Function to get the price of Bitcoin
async function getBitcoinPrice() {
    try {
        const response = await fetch('https://api.paywithflash.com/api/get_bitcoin_price', {
            method: 'POST', // if the API requires POST method
            headers: {
                'Content-Type': 'application/json',
            },
            // If the API requires a body payload, uncomment and edit the following line
            // body: JSON.stringify({ key: 'value' }), 
        });

        if (!response.ok) {
            // If the response is not OK, throw an error with the response status
            throw new Error(`API call failed with status: ${response.status}`);
        }

        const data = await response.json();

        // Assuming the API returns an object with a property that contains the Bitcoin price
        return data
    } catch (error) {
        console.error('API call failed:', error);
        throw error; // Rethrow the error to be handled by the caller
    }
}

// Function to go through the plans and make sure we have two columns: price in sats & price in dollars
async function convertPrices(plans) {
    try {
        const bitcoinPrice = await getBitcoinPrice(); // Assuming this function fetches the current price of Bitcoin in dollars
        const oneBitcoinInSats = 100000000; // 100 million satoshis in one bitcoin

        return plans.map(plan => {
            let priceInDollars, priceInSats;

            if (plan.currency === "Dollars") {
                priceInDollars = parseFloat(plan.price);
                priceInSats = Math.round((priceInDollars / bitcoinPrice) * oneBitcoinInSats);
            } else if (plan.currency === "Satoshis") {
                priceInSats = parseFloat(plan.price);
                priceInDollars = ((priceInSats / oneBitcoinInSats) * bitcoinPrice).toFixed(2);
            }

            return {
                ...plan,
                priceInDollars,
                priceInSats
            };
        });
    } catch (error) {
        console.error('Conversion failed:', error);
        return plans; // Return original plans in case of error
    }
}


export default function SelectSubscriptionForUnpaidSubscription({ appName, userMembershipForFlashId, imageUrl, plans, userData, onSelectPlan }) {
    const [updatedPlans, setUpdatedPlans] =  useState([]);
    const [selectedPlan, setSelectedPlan] = useState ({});
    const [loading, setLoading] = useState (false);
    const [flashLogo, setFlashLogo] = useState("/flash-credit-1.png"); // Initial logo
    // Sort plans by price in descending order
    const sortedPlans = plans.sort((a, b) => b.priceInDollars - a.priceInDollars);

    // useEffect hook to get the priceInSats and priceInDollars
    useEffect(() => {
        async function updatePlans() {
            const newPlans = await convertPrices(plans);
            setUpdatedPlans(newPlans);
        }

        if (plans !== null) {
            updatePlans();
        }
    }, [plans]);

    // Function to handle plan selection
    const handleSelectPlan = (plan) => {
        
        if (JSON.stringify(plan) === '{}') return ;
        onSelectPlan(plan);
    };

    // Check if plans is truthy and an array before rendering
    if (!Array.isArray(plans) || !plans) {
        return <div>No plans available or plans data is not valid.</div>;
    }

    return (
        <>
            {loading && (
                <div className="absolute inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                    <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
                </div>
            )}
            <div className="flex-1 flex flex-col justify-center text-black md:w-full bg-white px-0 rounded-sm relative"> {/* Add relative positioning here */}
                <div className="flex shrink-0 items-center justify-center">
                    <img
                        className="w-full h-24 mb-4 object-cover rounded-t-sm rounded-b-none" // Tailwind CSS for width and margin bottom
                        src={imageUrl}
                        alt="Image"
                    />
                    {/* Overlay label positioned absolutely within the parent div */}
                    <div className="absolute top-0 w-full flex justify-center"> {/* Position the label on top */}
                        {/* <div className="bg-white rounded-md px-2 py-1 text-xs font-medium text-orange-600 mt-2">Subscribe in Sats ₿</div> */}
                    </div>
                </div>

                <div className="flex shrink-0 items-center justify-center">
                    <img
                        className="w-20 h-20 mb-3 object-cover rounded-full border-4 border-white -mt-12 z-20" // Tailwind CSS for width, margin bottom, and z-index
                        src={imageUrl}
                        alt="Image"
                    />
                </div>


                {/* <div className=" flex text-center justify-center items-center">
        <div className="text-center w-fit bg-orange-100  rounded-md px-2 py-1 text-xs font-medium mb-3 text-orange-600">Subscribe in Sats ₿</div>
        </div> */}
                <h2 className="text-center text-2xl font-bold mb-0 text-black">
                    {appName}
                </h2>
                <div className="px-4 ">
                    <div className="text-center text-md text-gray-600 mb-6 px-10">
                        Select your plan
                    </div>
                    <ul role="list" className="space-y-4 w-full mb-4 px-4">
                        {updatedPlans.map((plan) => (
                            <li key={plan.id} className={cn({ "ring-purple-700": plan.id == selectedPlan.id, "ring-slate-200": plan.id != selectedPlan.id }, "ml-4 mt-4 mr-4 bg-white/80 cursor-pointer text-gray-800 rounded-lg ring hover:ring-purple-600 shadow overflow-hidden")}>
                                <div className="flex flex-row justify-between items-end p-4" onClick={() => { setSelectedPlan(plan) }}>
                                    <div className="flex flex-col items-start gap-x-3 ">
                                        <p className="text-md font-bold mt-0.5 px-1 leading-6 text-slate-700">{plan.name}</p>
                                        <p className={'rounded-md whitespace-nowrap mt-0.5 px-1  text-md text-slate-400 font-medium'}>
                                            {new Intl.NumberFormat().format(plan.price)} {plan.currency.toLowerCase()} / {plan.renewal_intervals.toLowerCase()}
                                        </p>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                <p className="text-sm text-gray-500 text-center px-10 mb-2">Your wallet will be automatically charged at each new renewal period.<br /> You can pause or cancel anytime.</p>
                <div className="flex w-full  mb-0 mt-3 px-11 text-slate-400 text-center justify-center items-center">
                    <button
                        type="button"
                        onClick={() => { handleSelectPlan(selectedPlan) }}
                        className="flex items-center w-full text-md justify-center rounded-lg bg-purple-700 px-3 py-3 text-sm font-semibold leading-6 text-white hover:bg-purple-800 focus:outline-none focus:ring-2 focus:ring-purple-800 focus:ring-offset-2 custom-button2"
                    >
                        Link my wallet
                    </button>
                </div>
                <p className="sticky w-full bottom-0 mt-4 pb-3 text-sm text-slate-400 font-medium text-center">

                    <a href="https://paywithflash.com" target="_blank" rel="noopener noreferrer" className="mx-auto">
                        <img
                            src={flashLogo}
                            alt="Flash"
                            className="inline h-5 ml-1 align-middle" // Adjust size as needed
                            onMouseEnter={() => setFlashLogo("/flash-credit-2.png")} // Change logo on hover
                            onMouseLeave={() => setFlashLogo("/flash-credit-1.png")} // Revert logo on mouse leave
                        />
                    </a>
                </p>
            </div>
        </>
    );
}
