import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

function formatDate(dateString) {
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  return new Date(dateString).toLocaleDateString(undefined, options);
}


// Define the function to fetch user transactions based on userPublicKey and flashId
const getUserTransactions = async (userPublicKey, flashId) => {
  // Construct the URL with query parameters
  const apiUrl = `https://api.paywithflash.com/api/get_outgoing_new_transactions_by_user_and_flash_id`;
  const params = new URLSearchParams({
    user_public_key: userPublicKey,
    flash_id: flashId
  });

  try {
    // Make the GET request to the server
    const response = await fetch(`${apiUrl}?${params}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // Add any other headers your API might need for authentication or other purposes
      }
    });

    // Check if the response is successful
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // Parse the JSON response body
    const data = await response.json();
    return data.transactions;  // This will be the JSON object containing the transactions list
  } catch (error) {
    console.error('Failed to fetch transactions:', error);
    // Handle errors or rejections here, maybe return an error state or message
    return { error: error.message };
  }
};

const ManageSubscriptionUserSubscribedScreen = ({ payerPublicKey, selectedMembership, flashId, encodedParams }) => {

  const location = useLocation ();

  const manageSubscriptionPageUrl = `https://paywithflash.com/manage-subscription?flashId=${flashId}&params=${encodedParams}`;

  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isChecked, setIsChecked] = React.useState(true);
  const [displayedTransactions, setDisplayedTransactions] = useState([]);
  const [currentEndIndex, setCurrentEndIndex] = useState(3);

  const handleInputChange = (event) => {
    setIsChecked(event.target.checked);
  };

  // Function to handle loading more transactions
  const loadMoreTransactions = () => {
    const nextEndIndex = currentEndIndex + 3;
    const moreTransactions = transactions.slice(currentEndIndex, nextEndIndex);
    setDisplayedTransactions([...displayedTransactions, ...moreTransactions]);
    setCurrentEndIndex(nextEndIndex);
  };

  useEffect(() => {
    const loadTransactions = async () => {
      setIsLoading(true);
      const transactions = await getUserTransactions(payerPublicKey, selectedMembership.flash_id); // Assume flashId is part of selectedMembership

      setTransactions(transactions || []);
      setDisplayedTransactions(transactions.slice(0, 3)); // Initially display the first 5 transactions
      setIsLoading(false);

    };

    loadTransactions();
  }, [payerPublicKey, selectedMembership]);

  return (
    <div className="max-w-4xl w-full sm:w-2/3 mx-auto mt-1 mb-1 p-8 bg-white rounded-lg shadow">
      <h2 className="text-2xl font-bold text-center mb-6">Your Subscription</h2>

      {/* Selected Membership card */}
      <div className="flex flex-col items-center justify-center space-y-4 p-4 gap-2 w-full max-w-md mx-auto">
      <label className="cursor-pointer block w-full">
        <input
          type="radio"
          className="peer sr-only"
          name="pricing"
          checked={isChecked}
          onChange={handleInputChange}
          readOnly={true} // Change this if you want to make it editable
        />
        <div className="rounded-md border px-5 py-3 text-gray-500 ring-2 ring-transparent transition-all shadow peer-checked:text-gray-800 peer-checked:ring-gray-800 peer-checked:ring-offset-2">
          <div className="flex flex-col gap-1">
            <div className="flex items-center justify-between">
              <p className="text-sm font-semibold uppercase text-gray-500">{selectedMembership.name}</p>
              <svg width="24" height="24" viewBox="0 0 24 24">
                <path fill="currentColor" d="m10.6 13.8l-2.175-2.175q-.275-.275-.675-.275t-.7.3q-.275.275-.275.7q0 .425.275.7L9.9 15.9q.275.275.7.275q.425 0 .7-.275l5.675-5.675q.275-.275.275-.675t-.3-.7q-.275-.275-.7-.275q-.425 0-.7.275ZM12 22q-2.075 0-3.9-.788q-1.825-.787-3.175-2.137q-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175q1.35-1.35 3.175-2.138Q9.925 2 12 2t3.9.787q1.825.788 3.175 2.138q1.35 1.35 2.137 3.175Q22 9.925 22 12t-.788 3.9q-.787 1.825-2.137 3.175q-1.35 1.35-3.175 2.137Q14.075 22 12 22Z" />
              </svg>
            </div>
            <p className="mt-0.5 text-sm text-gray-500">{ReactHtmlParser(selectedMembership.description)}</p>
            <div className="flex items-end justify-between">
              <p><span className="text-lg font-bold">${selectedMembership.PriceInDollars}</span> / {selectedMembership.renewal_intervals}</p>
              <p className="text-sm font-bold" style={{ fontVariant: 'small-caps' }}>{selectedMembership.PriceInSatoshis} sats / {selectedMembership.renewal_intervals}</p>
            </div>
            {selectedMembership.next_payment_date && (
              <div className="flex items-end justify-between text-sm">
                <p>Next Payment Date: {' '}<span className="text-sm font-bold">{formatDate(selectedMembership.next_payment_date)}</span></p>
              </div>
            )}
          </div>
        </div>
      </label>
    </div>

      {/* Display user transactions */}
      <div className="flex flex-col items-start">
        <h3 className="text-xl font-semibold text-center my-4">Past Payments</h3>
        {isLoading ? (
          <p>Loading transactions...</p>
        ) : error ? (
          <p className="text-red-500">{error}</p>
        ) : (
          <>
            <ul role="list" className="divide-y divide-gray-100 w-full max-w-xl">
              {displayedTransactions.map((transaction) => (
                <li key={transaction.id} className="flex justify-left gap-x-4 py-2 items-center ">
                  {transaction.transaction_direction === 'incoming' ? (
                    <svg className="h-5 w-5 text-indigo-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                    </svg>
                  ) : (
                    <svg className="h-5 w-5 text-slate-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M15 12H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                    </svg>
                  )}
                  <div className="min-w-0 flex-1">
                    <p className="text-sm font-semibold leading-6 text-slate-900">
                      {transaction.flash_type}
                    </p>
                    <p className="mt-0 truncate text-xs leading-5 text-slate-500">{formatDate(transaction.transaction_date)}</p>
                  </div>
                  <div className="min-w-0 flex-1 text-right">
                    <p className="text-sm font-semibold leading-6 text-slate-900">
                      ${transaction.amount_in_usd} USD
                    </p>
                    <p className="text-sm font-regular leading-6 text-slate-500">
                      {transaction.amount_in_sats} sats
                    </p>
                  </div>
                </li>
              ))}
            </ul>
            {currentEndIndex < transactions.length && (
              <button onClick={loadMoreTransactions} aria-label="Load more" className="mt-4 flex items-center justify-center space-x-2">
              <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                  <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
              </svg>
              <span className="text-xs">View more</span> {/* Adding the "View more" text */}
          </button>
          
            )}
          </>
        )}
      </div>

    </div>
  );
};

export default ManageSubscriptionUserSubscribedScreen;
