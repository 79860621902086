import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { nip04, nip19, SimplePool, finishEvent, getPublicKey, generatePrivateKey } from 'nostr-tools';
import { v4 as uuidv4 } from 'uuid';
import Lottie from 'react-lottie';

import ProductOrderFormGeyser from '../components/SubscriptionPage/ProductOrderFormGeyser';
import ProductOrderFormDisplay from '../components/SubscriptionPage/ProductOrderFormDisplay';
import ProductPayment from '../components/SubscriptionPage/ProductPayment';
import PaymentSuccessScreen from '../components/SubscriptionPage/PaymentSuccessScreen';
import WalletConnectButton from '../components/FlashAccount/WalletConnectButton';
import Memberships from '../components/SubscriptionPage/Memberships';
import UserAlreadySubscribedScreen from '../components/SubscriptionPage/UserAlreadySubscribedScreenGeyser';
import confettiAnimation from '../assets/confetti_animation.json';

// Function to get the price of Bitcoin
async function getBitcoinPrice() {
  try {
    const response = await fetch('https://api.paywithflash.com/api/get_bitcoin_price', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`API call failed with status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('API call failed:', error);
    throw error;
  }
}

const convertPrices = async (currency, value) => {
  const bitcoinPriceUSD = await getBitcoinPrice();

  if (currency === "Dollars") {
    const priceInSatoshis = Math.round(value / bitcoinPriceUSD * 100000000);
    return { priceInDollars: value, priceInSatoshis };
  } else if (currency === "Satoshis") {
    const priceInDollars = (value / 100000000 * bitcoinPriceUSD).toFixed(2);
    return { priceInDollars, priceInSatoshis: value };
  }
};

async function sendSubscriptionPurchaseConfirmationEmail(flashName, selectedMembership, recipientEmail, transactionId) {
  const apiUrl = 'https://api.paywithflash.com/api/send_purchase_confirmation_for_subscription';

  const requestData = {
    flash_name: flashName,
    selected_membership: selectedMembership,
    recipient_email: recipientEmail,
    transaction_id: transactionId
  };

  try {
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(`Failed to send purchase confirmation: ${errorData.error || response.status}`);
    }

    const responseData = await response.json();
    console.log('Email sent successfully:', responseData);
  } catch (error) {
    console.error('Error sending purchase confirmation email:', error.message);
    throw error;
  }
}

async function sendSubscriptionPurchaseConfirmationNostrDM(flashName, selectedMembership, recipientNpub, transactionId) {
  const relayUrls = [
    "wss://relay.paywithflash.com",
    "wss://relay.damus.io",
    "wss://relay.primal.net",
    "wss://relay.snort.social",
    "wss://relay.nostr.band"
  ];
  const flashPrivateKey = process.env.REACT_APP_NOSTR_PRIVATE_KEY;
  const flashPublicKey = process.env.REACT_APP_NOSTR_PUBLIC_KEY;

  const recipientHexPublicKey = await convertNostrToHex(recipientNpub);

  const message = `
    Congratulations on your purchase!
    Plan ${selectedMembership.name} purchased from ${flashName} for $${selectedMembership.PriceInDollars} (${selectedMembership.PriceInSatoshis} satoshis).

    Transaction reference: ${transactionId}
    Please note: We are the payment gateway and not responsible for the subscription. Should you have any inquiries regarding your order, please reach out to the vendor directly.
  `;

  const ciphertext = await nip04.encrypt(flashPrivateKey, recipientHexPublicKey, message);

  let event = {
    kind: 4,
    pubkey: flashPublicKey,
    tags: [['p', recipientHexPublicKey]],
    content: ciphertext,
    created_at: Math.floor(Date.now() / 1000)
  };

  event = finishEvent(event, flashPrivateKey);

  const pool = new SimplePool();
  const publishResults = await Promise.allSettled(pool.publish(relayUrls, event));

  publishResults.forEach((result, index) => {
    if (result.status === 'fulfilled') {
      // console.log(`DM successfully published to relay at ${relayUrls[index]}.`);
    } else {
      console.error(`An error occurred while publishing to relay at ${relayUrls[index]}:`, result.reason);
    }
  });
}

async function convertNostrToHex(npub) {
  try {
    const { type, data } = nip19.decode(npub);
    if (type === 'npub' || type === 'nsec') {
      return data;
    } else {
      throw new Error('Invalid Nostr key. Expected an "npub" key.');
    }
  } catch (error) {
    throw new Error(`Invalid Nostr key: ${npub}. ${error.message}`);
  }
}


const getFlashById = async (flashId) => {
  const API_URL = 'https://api.paywithflash.com/geyser/api/get_geyser_flash_from_id';

  try {
    const response = await fetch(`${API_URL}?flash_id=${encodeURIComponent(flashId)}`);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();

    if (data.error) {
      console.error('Error fetching flash data:', data.error);
      return null;
    }

    return data;
  } catch (error) {
    console.error('Error in getFlashNameById:', error);
    return null;
  }
};

async function getMembershipsByFlashId(flashId) {
  try {
    const response = await fetch(`https://api.paywithflash.com/geyser/api/get_geyser_memberships_by_flash_id?geyser_flash_id=${encodeURIComponent(flashId)}`);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status} ${response.statusText}`);
    }

    let data = await response.json();

    const promises = data.memberships.map(async (membership) => {
      const prices = await convertPrices(membership.currency, Number(membership.price));
      return {
        ...membership,
        PriceInDollars: prices.priceInDollars,
        PriceInSatoshis: prices.priceInSatoshis
      };
    });

    const updatedMemberships = await Promise.all(promises);

    return updatedMemberships;
  } catch (error) {
    console.error('Error fetching memberships:', error);
    throw error;
  }
}

// Function that calls the API to check if the user has a membership for the flash_id (look in table user_memberships where user_public_key = userPublicKey and flash_id = flashId)
async function getUserMembershipsForFlashId(userPublicKey, flashId) {
  try {
      const response = await fetch(`https://api.paywithflash.com/geyser/api/get_geyser_user_memberships_for_flash_id?user_public_key=${encodeURIComponent(userPublicKey)}&geyser_flash_id=${encodeURIComponent(flashId)}`);

      if (!response.ok) {
          // If the response status code is not in the 200-299 range,
          // throw an error with the status text
          throw new Error(`HTTP error! status: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      // You might want to return the data here if you need to use it where the function is called
      return data.geyser_user_memberships;
  } catch (error) {
      // Log the error to the console
      console.error('Error fetching user memberships:', error);
      // Optionally, return or handle the error in another way
      throw error;
  }
}

async function getMembershipById(membership_id) {
  try {
    const response = await fetch(`https://api.paywithflash.com/geyser/api/get_geyser_membership_by_id?id=${encodeURIComponent(membership_id)}`);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status} ${response.statusText}`);
    }
    const data = await response.json();
    return data.membership;
  } catch (error) {
    console.error('Error fetching membership', error);
    throw error;
  }
}

async function saveWalletToDB(userWalletName, walletNwcUrl, userPublicKey) {
  const selectedWalletName = await getWalletNameFromNWCString(walletNwcUrl);
  let encrypted_nwc_url;
  if (typeof walletNwcUrl !== 'undefined' && walletNwcUrl !== null && walletNwcUrl !== "") {
    encrypted_nwc_url = await fetchEncryptedContent(userPublicKey, walletNwcUrl);
  } else {
    encrypted_nwc_url = "";
  }

  const formData = new FormData();
  formData.append('user_public_key', userPublicKey);
  formData.append('wallet_name', selectedWalletName);
  formData.append('ln_address', "");
  formData.append('user_wallet_name', userWalletName);
  formData.append('nwc_url', encrypted_nwc_url);

  try {
    const response = await fetch('https://api.paywithflash.com/api/save_wallet_to_db', {
      method: 'POST',
      body: formData
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return data.wallet_id;
  } catch (error) {
    console.error('There was a problem with the fetch operation:', error);
    throw error;
  }
}

async function getWalletNameFromNWCString(nwcString) {
  const walletsAvailable = ["Alby", "Mutiny"];
  const lowerCaseNWCString = nwcString.toLowerCase();

  for (const walletName of walletsAvailable) {
    if (lowerCaseNWCString.includes(walletName.toLowerCase())) {
      return walletName;
    }
  }

  return "Custom";
};

async function fetchEncryptedContent(userPublicKey, propertiesToEncrypt) {
  try {
    const response = await fetch(`https://nostr-eggs-server-skphk.ondigitalocean.app/api/encrypt_content`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        userPublicKey: userPublicKey,
        propertiesToDecrypt: propertiesToEncrypt,
      }),
    });

    if (!response.ok) {
      throw new Error(`API call failed with status: ${response.status}`);
    }

    const data = await response.json();
    return data.properties;
  } catch (error) {
    console.error('Error in fetchEncryptedContent:', error);
    throw error;
  }
};


async function fetchDecryptedContent(userPublicKey, propertiesToDecrypt) {
  try {
    const response = await fetch(`https://nostr-eggs-server-skphk.ondigitalocean.app/api/decrypt_content`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        userPublicKey: userPublicKey,
        encryptedProperties: propertiesToDecrypt,
      }),
    });

    if (!response.ok) {
      throw new Error(`API call failed with status: ${response.status}`);
    }

    const data = await response.json();
    return data.properties;
  } catch (error) {
    console.error('Error in fetchDecryptedContent:', error);
    throw error;
  }
};

async function createNewUser(email, flashPublicKey) {
  try {

    const keysResult = await createPrivateKey();
    const userPrivateKey = keysResult.newPrivateKey;
    const userPublicKey = keysResult.publicKey;

    const encryptedUserPrivateKey = await encryptUserPrivateKey(flashPublicKey, userPrivateKey);

    const password = uuidv4();

    const signupResult = await signupUser(email, password, encryptedUserPrivateKey, userPublicKey);

    return { userPublicKey, userPrivateKey };
  } catch (error) {
    console.error('Signup error:', error);
    return { userPublicKey: null, userPrivateKey: null };
  }
}


async function createNewUserWithPublicKey(userPublicKey) {
  try {
    const password = uuidv4();
    const signUpResults = await signupUser('', password, '', userPublicKey);
    return signUpResults;
  } catch (error) {
    console.log('Error creating the new user with userPublicKey:', error);
  }
}

async function signupUser(email, password, encryptedUserPrivateKey, publicKey) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: new URLSearchParams({
      email: email,
      password: password,
      privateKey: encryptedUserPrivateKey,
      publicKey: publicKey,
      verificationLinkRequired: false,
    })
  };

  return fetch('https://api.paywithflash.com/api/signup', requestOptions)
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      return data;
    })
    .catch(error => {
      console.error('There was an error during the signup:', error);
    });
}

async function checkUserEmailAddressInDb(emailAddress) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: new URLSearchParams({ email: emailAddress })
  };

  return fetch('https://api.paywithflash.com/api/check_email', requestOptions)
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      if (data.email_exists) {
        const userPublicKey = data.public_key;
        const encryptedPayerPrivateKey = data.private_key_encrypted;
        return { emailExists: true, userPublicKey, encryptedPayerPrivateKey };
      } else {
        return { emailExists: false };
      }
    })
    .catch(error => {
      console.error('There was a problem with the fetch operation:', error);
      return { emailExists: false };
    });
}


async function checkUserPublicKeyInDb(userPublicKey) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: new URLSearchParams({ userPublicKey: userPublicKey }),
  }

  return fetch('https://api.paywithflash.com/api/check_user_public_key', requestOptions)
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      return data.userPublicKeyExists;
    })
    .catch(error => {
      console.error('There was a problem with the fetch options:', error);
    });
}

async function createPrivateKey() {
  const newPrivateKey = generatePrivateKey();
  const publicKey = getPublicKey(newPrivateKey);

  return { newPrivateKey, publicKey };
};

async function encryptUserPrivateKey(flashPublicKey, userPrivateKey) {
  const encryptedUserPrivateKey = await nip04.encrypt(userPrivateKey, flashPublicKey, userPrivateKey);

  return encryptedUserPrivateKey;
}

async function getWalletFromId(walletId) {
  try {
    const response = await fetch(`https://api.paywithflash.com/api/get_wallet_from_id?wallet_id=${encodeURIComponent(walletId)}`);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status} ${response.statusText}`);
    }

    const walletDetails = await response.json();
    return walletDetails;
  } catch (error) {
    console.error('Error fetching wallet details:', error);
    throw error;
  }
}



const fetchLightningAddressDetails = async (lightningAddress) => {
  const [user, domain] = lightningAddress.split('@');
  const wellKnownUrl = `https://${domain}/.well-known/lnurlp/${user}`;
  
  try {
    const response = await fetch(wellKnownUrl);
    if (!response.ok) throw new Error('Network response was not ok');
    const data = await response.json();
    return data.callback; // This is the callback URL you're interested in
  } catch (error) {
    console.error("Error fetching Lightning Address details:", error);
    return null; // Handle error appropriately
  }
};



async function getLNURLDetails(lnurl) {
  let url;
  try {
    if (lnurl.startsWith('lnurl')) {
      // It's a bech32 encoded LNURL, decode it
      const decoded = bech32.decode(lnurl, 1024);
      url = Buffer.from(bech32.fromWords(decoded.words)).toString('utf-8');
    } else {
      // It's already a URL, use it directly
      url = lnurl;
    }
    const response = await fetch(url);
    if (!response.ok) {
        throw new Error(`Failed to fetch LNURL details, server responded with status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Failed to fetch LNURL details:', error);
    throw error;
  }
}

async function createZapRequest(senderPrivateKey, recipientPubKey, lnurl, amount, memo) {
  const relays = ['wss://relay.paywithflash.com'];
  
  const event = finishEvent({
    kind: 9734,
    content: memo,
    pubkey: getPublicKey(senderPrivateKey),
    created_at: Math.floor(Date.now() / 1000),
    tags: [
      ["relays", ...relays],
      ["amount", amount.toString()],
      ["lnurl", lnurl],
      ["p", recipientPubKey],
    ]
  }, senderPrivateKey);

  return encodeURI(JSON.stringify(event));
}

async function requestInvoice(zapRequestEvent, callbackURL, amount, lnurl) {
  const queryParams = `?amount=${amount}&nostr=${zapRequestEvent}&lnurl=${lnurl}`;
  const response = await fetch(`${callbackURL}${queryParams}`);
  const data = await response.json();

  if (data.pr) {
    return data.pr;
    // Now you can display the invoice to the user for payment
  } else {
    console.error('Error requesting invoice:', data.reason);
  }
}


// The function used to create the Zap Invoice
async function createZapInvoice(userPrivateKey, userPublicKey, lightningAddress, amount, memo) {
  try {
    // Resolve the Lightning Address to LNURL first
    const lnurl = await fetchLightningAddressDetails(lightningAddress);

    // Then, get LNURL details, which includes the callback URL
    const lnurlDetails = await getLNURLDetails(lnurl);

    // Create the zap request event
    const zapRequestEvent = await createZapRequest(userPrivateKey, userPublicKey, lnurl, amount * 1000, memo);

    // Request invoice by sending the zap event via callback URL
    const invoice = await requestInvoice(zapRequestEvent, lnurlDetails.callback, amount * 1000, lnurl);

    return invoice;
  } catch (error) {
    console.error("Error in createZapInvoice: ", error);
    throw error; // Rethrow the error for the caller to handle
  }
}

async function payZapInvoiceWithNwc(nostrWalletConnectUrl, invoice) {
  try {
    const response = await fetch('https://nostr-eggs-server-skphk.ondigitalocean.app/api/pay_zap_invoice_with_nwc', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        nostrWalletConnectUrl,
        invoice,
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    } else {
      // console.log("Successfully paid invoice")
    }

    const result = await response.json();
    return result.is_paid;
  } catch (error) {
    console.error('Error making payment:', error);
    throw error;
  }
}

function getNextRenewalDateISOString(renewalInterval) {
  const currentDate = new Date();

  switch (renewalInterval) {
    case "Yearly":
      currentDate.setFullYear(currentDate.getFullYear() + 1);
      break;
    case "Monthly":
      currentDate.setMonth(currentDate.getMonth() + 1);
      break;
    case "Weekly":
      currentDate.setDate(currentDate.getDate() + 7);
      break;
    case "Lifetime":
      currentDate.setFullYear(currentDate.getFullYear() + 100);
      break;
    default:
      throw new Error("Invalid renewal interval");
  }

  return currentDate.toISOString();
}

async function updateUserMembership(user_membership_id, userPublicKey, flashId, externalUuid, walletId, membershipId, isPaid, membershipStartDate, nextPaymentDate, status) {
  try {
    const response = await fetch('https://api.paywithflash.com/geyser/api/update_geyser_user_membership', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        user_membership_id: user_membership_id,
        user_public_key: userPublicKey,
        geyser_flash_id: flashId,
        external_uuid: externalUuid,
        wallet_id: walletId,
        geyser_membership_id: membershipId,
        is_paid: isPaid,
        membership_start_date: membershipStartDate,
        next_payment_date: nextPaymentDate,
        status: status
      })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error saving user membership:', error);
    throw error;
  }
}

async function saveUserMembership(userPublicKey, flashId, externalUuid, walletId, membershipId, isPaid, membershipStartDate, nextPaymentDate, status) {
  try {
    const response = await fetch('https://api.paywithflash.com/geyser/api/save_geyser_user_membership', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        user_public_key: userPublicKey,
        geyser_flash_id: flashId,
        external_uuid: externalUuid,
        wallet_id: walletId,
        geyser_membership_id: membershipId,
        is_paid: isPaid,
        membership_start_date: membershipStartDate,
        next_payment_date: nextPaymentDate,
        status: status
      })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    } else {
      console.log("Succesfully saved user membership")
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error saving user membership:', error);
    throw error;
  }
}


async function saveTransactionToDB(from_public_key, to_public_key, transactionDate, amountInSats, valueInUsd, flashId, flashName, flashType, toWalletId, toUserWalletName, fromWalletId, fromUserWalletName, lnurl) {
  const formData = new FormData();
  formData.append('from_public_key', from_public_key);
  formData.append('to_public_key', to_public_key);
  formData.append('transaction_date', transactionDate);
  formData.append('amount_in_sats', amountInSats);
  formData.append('value_in_usd', valueInUsd);
  formData.append('flash_id', flashId);
  formData.append('flash_name', flashName);
  formData.append('flash_type', flashType);
  formData.append('to_wallet_id', toWalletId !== null ? toWalletId : '');
  formData.append('to_user_wallet_name', toUserWalletName);
  formData.append('from_wallet_id', fromWalletId !== null ? fromWalletId : '');
  formData.append('from_user_wallet_name', fromUserWalletName);
  formData.append('lnurl', lnurl);

  try {
      const response = await fetch('https://api.paywithflash.com/api/save_new_transaction_to_db', {
          method: 'POST',
          body: formData,
      });

      if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Failed to save transaction to database');
      }

      const successData = await response.json();
      // console.log('Transaction successfully saved to database:', successData);
      return successData;
  } catch (error) {
      console.error('Error saving transaction to database:', error);
      throw error;
  }
}



async function sendMessageToSource(sourceUrl, data) {
  let targetWindow;
  let targetOrigin = '*';

  const effectiveSourceUrl = sourceUrl || document.referrer;
  if (effectiveSourceUrl) {
    const urlObj = new URL(effectiveSourceUrl);
    targetOrigin = urlObj.origin;
  }

  if (window.parent !== window) {
    targetWindow = window.parent;
  } else if (window.opener) {
    targetWindow = window.opener;
  }

  if (targetWindow) {
    targetWindow.postMessage(data, targetOrigin);
  }
};

const SubscriptionsPageGeyser = () => {
  const flashPrivateKey = process.env.REACT_APP_NOSTR_PRIVATE_KEY;
  const flashPublicKey = process.env.REACT_APP_NOSTR_PUBLIC_KEY;

  const { productId } = useParams();

  const [customerId, setCustomerId] = useState(null);
  const [product, setProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false); // New state for processing
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [downloadLink, setDownloadLink] = useState('');
  const [orderDetails, setOrderDetails] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [priceInSats, setPriceInSats] = useState();
  const [priceInDollars, setPriceInDollars] = useState();
  const [priceInZloty, setPriceInZloty] = useState();

  const [appName, setAppName] = useState('');
  const [flashDetails, setFlashDetails] = useState('');
  const [flashUrl, setFlashUrl] = useState('');
  const [flashId, setFlashId] = useState('');
  const [email, setEmail] = useState('');
  const [npub, setNpub] = useState('');
  const [isVerified, setIsVerified] = useState(false);
  const [externalUuid, setExternalUuid] = useState('');
  const [sourceUrl, setSourceUrl] = useState('');
  const [userMemberships, setUserMemberships] = useState('');
  const [memberships, setMemberships] = useState([]);
  const [loginMethod, setLoginMethod] = useState('email & nostr');

  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [userMembershipForFlashId, setUserMembershipForFlashId] = useState(null);
  const [selectedMembership, setSelectedMembership] = useState(null);
  const [isRenewal, setIsRenewal] = useState(null);

  const [userAlreadySubscribed, setUserAlreadySubscribed] = useState(false);
  const [walletNwcUrl, setWalletNwcUrl] = useState(null);
  const [flashWalletDetails, setFlashWalletDetails] = useState(null);
  const [payerPublicKey, setPayerPublicKey] = useState('');
  const [paymentSuccess, setPaymentSuccess] = useState('');
  const [transaction, setTransaction] = useState('');

  const handlePaymentSuccess = async (formValues, transaction) => {
    setPaymentSuccess(true);

    if (formValues.email) {
      await sendSubscriptionPurchaseConfirmationEmail(flashDetails.name, selectedMembership, formValues.email, transaction.transaction_id);
    } else if (formValues.npub) {
      await sendSubscriptionPurchaseConfirmationNostrDM(flashDetails.name, selectedMembership, formValues.npub, transaction.transaction_id);
    }
  };

  const handleOrderSubmit = async (formValues) => {
    try {
        setIsProcessing(true); // Show the loading animation
        setErrorMessage(''); // Clear previous error messages
        setOrderDetails(formValues);

        let payerPublicKey;
        let walletId;

        if (formValues.email) {
            const userKeys = await checkUserEmailAddressInDb(formValues.email);
            if (userKeys.emailExists) {
                payerPublicKey = userKeys.userPublicKey;
            } else {
                const newUserKeys = await createNewUser(formValues.email, flashPublicKey);
                payerPublicKey = newUserKeys.userPublicKey;
            }
            walletId = await saveWalletToDB(appName, walletNwcUrl, payerPublicKey);
        } else if (formValues.npub) {
            payerPublicKey = await convertNostrToHex(formValues.npub);
            const publicKeyExists = await checkUserPublicKeyInDb(payerPublicKey);
            if (!publicKeyExists) {
                const newUserData = await createNewUserWithPublicKey(payerPublicKey);
                if (!newUserData.user_id) {
                    throw new Error('An error occurred while saving the new user with PublicKey');
                }
            }
            walletId = await saveWalletToDB(appName, walletNwcUrl, payerPublicKey);
        } else {
            throw new Error('No valid contact method provided.');
        }

        setPayerPublicKey(payerPublicKey);

        const userMemberships = await getUserMembershipsForFlashId(payerPublicKey, flashId);
        if (userMemberships.length > 0) {
            const firstMembership = userMemberships[0];
            if (!firstMembership.is_paid) {
                setIsRenewal(true);
            } else if (firstMembership.is_paid) {
                setUserAlreadySubscribed(true);
                setIsProcessing(false); // Hide the loading animation
                return;
            }
        }

        const invoice = await createZapInvoice(
            payerPublicKey,
            flashDetails.user_public_key,
            flashDetails.lightning_address,
            selectedMembership.PriceInSatoshis,
            `Payment for ${selectedMembership.name} plan with ${appName}`
        );

        const is_paid = await payZapInvoiceWithNwc(formValues.walletNwcUrl, invoice);
        if (is_paid) {
            const currentDate = new Date().toISOString();
            const nextRenewalDate = getNextRenewalDateISOString(selectedMembership.renewal_intervals);

            if (isRenewal) {
                await updateUserMembership(userMemberships[0].id, payerPublicKey, flashId, externalUuid, walletId, selectedMembership.id, "true", currentDate, nextRenewalDate, "active");
            } else {
                await saveUserMembership(payerPublicKey, flashId, externalUuid, walletId, selectedMembership.id, "true", currentDate, nextRenewalDate, "active");
            }

            const transaction = await saveTransactionToDB(payerPublicKey, flashDetails.user_public_key, currentDate, selectedMembership.PriceInSatoshis, selectedMembership.PriceInDollars, flashId, flashDetails.name, "Geyser Subscription Plan", '', flashDetails.lightning_address, walletId, appName, invoice);
            setTransaction(transaction);

            handlePaymentSuccess(formValues, transaction);

            const postData = {
                user_has_paid: "true",
                user_membership_name: selectedMembership.name,
                user_public_key: payerPublicKey,
            };
            sendMessageToSource(sourceUrl, postData);
        }
    } catch (error) {
        setIsProcessing(false);
        setErrorMessage("There was an error processing the payment. Check your wallet and try again later.");
        console.error("Error during payment:", error);
    } finally {
        setIsProcessing(false); // Hide the loading animation
    }
};

  
  

  const handleCodeConfirmedWrapper = async (contactMethod, contact) => {
    let payerPublicKey;
    if (contactMethod === 'email') {
      const userKeys = await checkUserEmailAddressInDb(contact);
      payerPublicKey = userKeys.userPublicKey;
    } else if (contactMethod === 'npub') {
      payerPublicKey = await convertNostrToHex(contact);
    }

    setPayerPublicKey(payerPublicKey);

    const userMemberships = await getUserMembershipsForFlashId(payerPublicKey, flashId);
    if (userMemberships.length > 0) {
      const firstMembership = userMemberships[0];
      if (firstMembership.is_paid === 'true') {
        const membershipId = firstMembership.membership_id;
        const membership = await getMembershipById(membershipId);
        setSelectedMembership(membership);
        setUserAlreadySubscribed(true);
      }
    } else {
      setUserAlreadySubscribed(false);
    }
  };

  const handleSelectedMembershipChange = (membership) => {
    setSelectedMembership(membership);
    setSelectedPlan(membership);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const flashId = queryParams.get('flashId');
    const encodedParams = queryParams.get('params');
    setFlashId(flashId);
  
    if (encodedParams) {
      try {
        const decodedString = atob(encodedParams);
        const params = JSON.parse(decodedString);
  
        // Extract and validate parameters
        const { email, npub, is_verified, external_uuid } = params;
  
        // Log parameters for debugging
        console.log("Email:", email);
        console.log("Npub:", npub);
        console.log("Is Verified:", is_verified);
        console.log("External UUID:", external_uuid);
  
        // Set state with validated parameters
        if (email) {
          setEmail(email);
        }
        if (npub) {
          setNpub(npub);
        }
        setIsVerified(is_verified || false);
        setExternalUuid(external_uuid || '');
  
        // Set default contact method
        if (npub) {
          setLoginMethod('nostr');
        } else if (email) {
          setLoginMethod('email');
        }
      } catch (error) {
        console.error('Error decoding parameters:', error);
      }
    }
  
    setSourceUrl(document.referrer);
  }, []);
  


  // Encoding useEffect for testing
  useEffect(() => {
    function encodeParams(params) {
      const jsonString = JSON.stringify(params);
      return btoa(jsonString);
    }

    const params = {
      // email: 'test@example.com',
      npub: 'npub1elcvlauvyr9sx23qpa2yu7a9ytys50lhgukvj7wcrw6n7n9j536sjhgyfa',
      is_verified: true,
      // external_uuid: 'abc123'
    };

    const encodedParams = encodeParams(params);
    console.log(encodedParams);  // Use this in your URL
  }, []);

  useEffect(() => {
    if (externalUuid) {
      getUserMembershipsForFlashId('', flashId)
        .then(async (data) => {
          if (data.status) {
            const membershipId = data.userMembership[0].membership_id;
            const userPublicKey = data.userMembership[0].user_public_key;
            const membership = await getMembershipById(membershipId);
            setSelectedMembership(membership);
            setPayerPublicKey(userPublicKey);
            setUserAlreadySubscribed(true);
            return;
          }
        });
    } else if (email && !externalUuid) {
      checkUserEmailAddressInDb(email)
        .then(async (data) => {
          if (data) {
            const userPublicKey = data;
            setPayerPublicKey(userPublicKey);
            const userMemberships = await getUserMembershipsForFlashId(userPublicKey, flashId);
            if (userMemberships.length > 0) {
              const firstMembership = userMemberships[0];
              if (firstMembership.is_paid === 'true') {
                const membershipId = firstMembership.membership_id;
                const membership = await getMembershipById(membershipId);
                setSelectedMembership(membership);
                setUserAlreadySubscribed(true);
                return;
              }
            } else {
              setUserAlreadySubscribed(false);
            }
          }
        });
    }

    if (flashId) {
      setIsLoading(true);

      const fetchFlashDetails = async () => {
        try {
          const flash = await getFlashById(flashId);
          if (flash) {
            setFlashDetails(flash);
            setPriceInDollars(flash.PriceInDollars);
            setPriceInSats(flash.PriceInSatoshis);
            setAppName(flash.name || '');
            setFlashUrl(flash.url || '');
            // setLoginMethod(flash.flash_login_method || '');
          } else {
            setFlashDetails({});
            setPriceInDollars(0);
            setPriceInSats(0);
            setAppName('');
            setFlashUrl('');
            // setLoginMethod('');
          }
        } catch (error) {
          console.error('Failed to load flash details:', error);
          setFlashDetails({});
        }

        try {
          const memberships = await getMembershipsByFlashId(flashId);
          setMemberships(memberships || []);
        } catch (error) {
          console.error('Failed to load memberships:', error);
          setMemberships([]);
        }

        setIsLoading(false);
      };

      fetchFlashDetails();
    }
  }, [flashId, email, externalUuid]);

  useEffect(() => {
    if (orderDetails) {
      getUserMembershipsForFlashId(orderDetails.npub, flashId)
        .then(userMemberships => {
          if (userMemberships) {
            setUserMemberships(userMemberships);
          }
          setIsLoading(false);
        });
    }
  }, [orderDetails]);


  if (error) return <div>Error: {error}</div>;

  return (
    <>
      {(isLoading || isProcessing) && (
        <div className="loading-container absolute inset-0 flex items-center justify-center bg-black bg-opacity-60" style={{ zIndex: '100' }}>
          <div className="geyser_loader ease-linear rounded-full border-4 border-t-4 border-gray-800 h-12 w-12 mb-4"></div>
        </div>
      )}
      <div className="flex justify-center items-center lg:p-32">
        <div className="w-full bg-white rounded-xl overflow-hidden">
          <div className="grid grid-cols-2 gap-2 lg:divide-x-2">
            <div className="lg:col-span-1 sm:col-span-2 col-span-2 p-8 flex flex-col justify-center items-center">
              <div className="uppercase tracking-wide font-semibold text-gray-500">{flashDetails.name}</div>
              <div className="flex justify-center items-center md:flex-shrink-0 pb-3 w-2/5 sm:w-2/5 md:w-2/5 lg:w-3/5 mt-2">
                <img
                  className="object-contain rounded-xl"
                  src={flashDetails.flash_image_url}
                  alt="First Image"
                />
              </div>
            </div>
            <div className="lg:col-span-1 sm:col-span-2 col-span-2 flex flex-col justify-center">
              {!orderDetails && !userAlreadySubscribed && (
                <ProductOrderFormGeyser
                  onOrderSubmitted={handleOrderSubmit}
                  walletNwcUrl={walletNwcUrl}
                  setWalletNwcUrl={setWalletNwcUrl}
                  memberships={memberships}
                  onSelectedMembershipChange={handleSelectedMembershipChange}
                  eMail={email}
                  nPub={npub}
                  loginMethod={loginMethod}
                  appName={appName}
                  isVerified={isVerified}
                  handleCodeConfirmedWrapper={handleCodeConfirmedWrapper}
                  errorMessage={errorMessage}
                  setErrorMessage={setErrorMessage}
                />
              )}
              {userAlreadySubscribed && (
                <UserAlreadySubscribedScreen payerPublicKey={payerPublicKey} selectedMembership={selectedMembership} />
              )}
              {paymentSuccess && (
                <PaymentSuccessScreen selectedMembership={selectedMembership} orderDetails={orderDetails} />
              )}
            </div>
            <div className="col-span-2 flex justify-center items-center text-center text-xs text-gray-500 mt-12 mb-10">
              Powered by&nbsp;
              <a href="https://paywithflash.com" className="font-bold text-slate-500">Flash</a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
  
};

export default SubscriptionsPageGeyser;
