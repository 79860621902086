import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { nip04, nip19, SimplePool, finishEvent, getPublicKey, generatePrivateKey } from 'nostr-tools';
import { v4 as uuidv4 } from 'uuid';
import Lottie from 'react-lottie';

import ProductOrderFormVimeoOTT from '../components/SubscriptionPage/ProductOrderFormVimeoOTT';
import ProductOrderFormDisplay from '../components/SubscriptionPage/ProductOrderFormDisplay';
import ProductPayment from '../components/SubscriptionPage/ProductPayment';
import PaymentSuccessScreen from '../components/SubscriptionPage/PaymentSuccessScreen';
import WalletConnectButton from '../components/FlashAccount/WalletConnectButton';
import Memberships from '../components/SubscriptionPage/Memberships';
import UserAlreadySubscribedScreen from '../components/SubscriptionPage/UserAlreadySubscribedScreenVimeoOTT';
import confettiAnimation from '../assets/confetti_animation.json';

// Function to get the price of Bitcoin
async function getBitcoinPrice() {
  try {
    const response = await fetch('https://api.paywithflash.com/api/get_bitcoin_price', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`API call failed with status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('API call failed:', error);
    throw error;
  }
}

const convertPrices = async (currency, value) => {
  const bitcoinPriceUSD = await getBitcoinPrice();

  if (currency === "Dollars") {
    const priceInSatoshis = Math.round(value / bitcoinPriceUSD * 100000000);
    return { priceInDollars: value, priceInSatoshis };
  } else if (currency === "Satoshis") {
    const priceInDollars = (value / 100000000 * bitcoinPriceUSD).toFixed(2);
    return { priceInDollars, priceInSatoshis: value };
  }
};

async function sendSubscriptionPurchaseConfirmationEmail(flashName, selectedMembership, recipientEmail, transactionId) {
  const apiUrl = 'https://api.paywithflash.com/api/send_purchase_confirmation_for_subscription';

  const requestData = {
    flash_name: flashName,
    selected_membership: selectedMembership,
    recipient_email: recipientEmail,
    transaction_id: transactionId
  };

  try {
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(`Failed to send purchase confirmation: ${errorData.error || response.status}`);
    }

    const responseData = await response.json();
    // console.log('Email sent successfully:', responseData);
  } catch (error) {
    console.error('Error sending purchase confirmation email:', error.message);
    throw error;
  }
}

async function sendSubscriptionPurchaseConfirmationNostrDM(flashName, selectedMembership, recipientNpub, transactionId) {
  const relayUrls = [
    "wss://relay.paywithflash.com",
    "wss://relay.damus.io",
    "wss://relay.primal.net",
    "wss://relay.snort.social",
    "wss://relay.nostr.band"
  ];
  const flashPrivateKey = process.env.REACT_APP_NOSTR_PRIVATE_KEY;
  const flashPublicKey = process.env.REACT_APP_NOSTR_PUBLIC_KEY;

  const recipientHexPublicKey = await convertNostrToHex(recipientNpub);

  const message = `
    Congratulations on your purchase!
    Plan ${selectedMembership.name} purchased from ${flashName} for $${selectedMembership.PriceInDollars} (${selectedMembership.PriceInSatoshis} satoshis).

    Transaction reference: ${transactionId}
    Please note: We are the payment gateway and not responsible for the subscription. Should you have any inquiries regarding your order, please reach out to the vendor directly.
  `;

  const ciphertext = await nip04.encrypt(flashPrivateKey, recipientHexPublicKey, message);

  let event = {
    kind: 4,
    pubkey: flashPublicKey,
    tags: [['p', recipientHexPublicKey]],
    content: ciphertext,
    created_at: Math.floor(Date.now() / 1000)
  };

  event = finishEvent(event, flashPrivateKey);

  const pool = new SimplePool();
  const publishResults = await Promise.allSettled(pool.publish(relayUrls, event));

  publishResults.forEach((result, index) => {
    if (result.status === 'fulfilled') {
      // console.log(`DM successfully published to relay at ${relayUrls[index]}.`);
    } else {
      console.error(`An error occurred while publishing to relay at ${relayUrls[index]}:`, result.reason);
    }
  });
}

async function convertNostrToHex(npub) {
  try {
    const { type, data } = nip19.decode(npub);
    if (type === 'npub' || type === 'nsec') {
      return data;
    } else {
      throw new Error('Invalid Nostr key. Expected an "npub" key.');
    }
  } catch (error) {
    throw new Error(`Invalid Nostr key: ${npub}. ${error.message}`);
  }
}


const getFlashById = async (flashId) => {
  const API_URL = 'https://api.paywithflash.com/vimeo_ott/api/get_vimeo_ott_flash_from_id';

  try {
    const response = await fetch(`${API_URL}?flash_id=${encodeURIComponent(flashId)}`);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();

    if (data.error) {
      console.error('Error fetching flash data:', data.error);
      return null;
    }

    return data;
  } catch (error) {
    console.error('Error in getFlashNameById:', error);
    return null;
  }
};

async function getMembershipsByFlashId(flashId) {
  try {
    const response = await fetch(`https://api.paywithflash.com/vimeo_ott/api/get_vimeo_ott_memberships_by_flash_id?vimeo_ott_flash_id=${encodeURIComponent(flashId)}`);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status} ${response.statusText}`);
    }

    let data = await response.json();

    const promises = data.memberships.map(async (membership) => {
      const prices = await convertPrices(membership.currency, Number(membership.price));
      return {
        ...membership,
        PriceInDollars: prices.priceInDollars,
        PriceInSatoshis: prices.priceInSatoshis
      };
    });

    const updatedMemberships = await Promise.all(promises);

    return updatedMemberships;
  } catch (error) {
    console.error('Error fetching memberships:', error);
    throw error;
  }
}

// Function that calls the API to check if the user has a membership for the flash_id (look in table user_memberships where user_public_key = userPublicKey and flash_id = flashId)
async function getUserMembershipsForFlashId(userPublicKey, flashId) {
  try {
    const response = await fetch(`https://api.paywithflash.com/vimeo_ott/api/get_vimeo_ott_user_memberships_for_flash_id?user_public_key=${encodeURIComponent(userPublicKey)}&vimeo_ott_flash_id=${encodeURIComponent(flashId)}`);

    if (!response.ok) {
      // If the response status code is not in the 200-299 range,
      // throw an error with the status text
      throw new Error(`HTTP error! status: ${response.status} ${response.statusText}`);
    }

    const data = await response.json();
    // You might want to return the data here if you need to use it where the function is called
    return data.vimeo_ott_user_memberships;
  } catch (error) {
    // Log the error to the console
    console.error('Error fetching user memberships:', error);
    // Optionally, return or handle the error in another way
    throw error;
  }
}

async function getMembershipById(membership_id) {
  try {
    const response = await fetch(`https://api.paywithflash.com/vimeo_ott/api/get_vimeo_ott_membership_by_id?id=${encodeURIComponent(id)}`);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status} ${response.statusText}`);
    }
    const data = await response.json();
    return data.membership;
  } catch (error) {
    console.error('Error fetching membership', error);
    throw error;
  }
}

// Function to be able to add new wallets
async function saveWalletToDB(userWalletName, walletNwcUrl, userPublicKey) {
  // Call function to get the wallet name based on the NWCUrl
  const selectedWalletName = await getWalletNameFromNWCString(walletNwcUrl);
  // Encrypt the nwcUrl
  let encrypted_nwc_url;
  if (typeof walletNwcUrl !== 'undefined' && walletNwcUrl !== null && walletNwcUrl !== "") {
    encrypted_nwc_url = await fetchEncryptedContent(userPublicKey, walletNwcUrl);
  } else {
    encrypted_nwc_url = "";
  }

  const formData = new FormData();
  formData.append('user_public_key', userPublicKey);
  formData.append('wallet_name', selectedWalletName);
  formData.append('ln_address', "");
  formData.append('user_wallet_name', userWalletName);
  formData.append('nwc_url', encrypted_nwc_url);

  try {
    const response = await fetch('https://api.paywithflash.com/api/save_wallet_to_db', {
      method: 'POST',
      body: formData
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return data.wallet_id;
  } catch (error) {
    console.error('There was a problem with the fetch operation:', error);
    throw error;
  }
}

async function getWalletNameFromNWCString(nwcString) {
  const walletsAvailable = ["Alby", "Mutiny"];
  const lowerCaseNWCString = nwcString.toLowerCase();

  for (const walletName of walletsAvailable) {
    if (lowerCaseNWCString.includes(walletName.toLowerCase())) {
      return walletName;
    }
  }

  return "Custom";
};

async function fetchEncryptedContent(userPublicKey, propertiesToDecrypt) {
  try {
    const response = await fetch(`https://nostr-eggs-server-skphk.ondigitalocean.app/api/encrypt_content`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        userPublicKey: userPublicKey,
        propertiesToDecrypt: propertiesToDecrypt,
      }),
    });

    if (!response.ok) {
      throw new Error(`API call failed with status: ${response.status}`);
    }

    const data = await response.json();
    return data.properties;
  } catch (error) {
    console.error('Error in fetchEncryptedContent:', error);
    throw error;
  }
};


async function fetchDecryptedContent(userPublicKey, propertiesToDecrypt) {
  try {
    const response = await fetch(`https://nostr-eggs-server-skphk.ondigitalocean.app/api/decrypt_content`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        userPublicKey: userPublicKey,
        encryptedProperties: propertiesToDecrypt,
      }),
    });

    if (!response.ok) {
      throw new Error(`API call failed with status: ${response.status}`);
    }

    const data = await response.json();
    return data.properties;
  } catch (error) {
    console.error('Error in fetchDecryptedContent:', error);
    throw error;
  }
};

async function createNewUser(email, flashPublicKey) {
  try {

    const keysResult = await createPrivateKey();
    const userPrivateKey = keysResult.newPrivateKey;
    const userPublicKey = keysResult.publicKey;

    const encryptedUserPrivateKey = await encryptUserPrivateKey(flashPublicKey, userPrivateKey);

    const password = uuidv4();

    const signupResult = await signupUser(email, password, encryptedUserPrivateKey, userPublicKey);

    return { userPublicKey, userPrivateKey };
  } catch (error) {
    console.error('Signup error:', error);
    return { userPublicKey: null, userPrivateKey: null };
  }
}


async function createNewUserWithPublicKey(userPublicKey) {
  try {
    const password = uuidv4();
    const signUpResults = await signupUser('', password, '', userPublicKey);
    return signUpResults;
  } catch (error) {
    console.log('Error creating the new user with userPublicKey:', error);
  }
}

async function signupUser(email, password, encryptedUserPrivateKey, publicKey) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: new URLSearchParams({
      email: email,
      password: password,
      privateKey: encryptedUserPrivateKey,
      publicKey: publicKey,
      verificationLinkRequired: false,
    })
  };

  return fetch('https://api.paywithflash.com/api/signup', requestOptions)
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      return data;
    })
    .catch(error => {
      console.error('There was an error during the signup:', error);
    });
}

async function checkUserEmailAddressInDb(emailAddress) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: new URLSearchParams({ email: emailAddress })
  };

  return fetch('https://api.paywithflash.com/api/check_email', requestOptions)
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      if (data.email_exists) {
        const userPublicKey = data.public_key;
        const encryptedPayerPrivateKey = data.private_key_encrypted;
        return { emailExists: true, userPublicKey, encryptedPayerPrivateKey };
      } else {
        return { emailExists: false };
      }
    })
    .catch(error => {
      console.error('There was a problem with the fetch operation:', error);
      return { emailExists: false };
    });
}


async function checkUserPublicKeyInDb(userPublicKey) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: new URLSearchParams({ userPublicKey: userPublicKey }),
  }

  return fetch('https://api.paywithflash.com/api/check_user_public_key', requestOptions)
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      return data.userPublicKeyExists;
    })
    .catch(error => {
      console.error('There was a problem with the fetch options:', error);
    });
}

async function createPrivateKey() {
  const newPrivateKey = generatePrivateKey();
  const publicKey = getPublicKey(newPrivateKey);

  return { newPrivateKey, publicKey };
};

async function encryptUserPrivateKey(flashPublicKey, userPrivateKey) {
  const encryptedUserPrivateKey = await nip04.encrypt(userPrivateKey, flashPublicKey, userPrivateKey);

  return encryptedUserPrivateKey;
}


// Function to get the referral data from the db using user public key
async function fetchReferralDataWithPublicKey(userPublicKey) {
  const formData = new FormData();
  formData.append('userPublicKey', userPublicKey);
  try {

    const response = await fetch('https://api.paywithflash.com/api/get_referral_info_with_public_key', {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
    throw error; // Rethrowing the error for handling it further up in the call stack
  }
}

async function getWalletFromId(walletId) {
  try {
    const response = await fetch(`https://api.paywithflash.com/api/get_wallet_from_id?wallet_id=${encodeURIComponent(walletId)}`);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status} ${response.statusText}`);
    }

    const walletDetails = await response.json();
    return walletDetails;
  } catch (error) {
    console.error('Error fetching wallet details:', error);
    throw error;
  }
}





async function createZapInvoice(userPublicKey, encryptedUserNostrWalletConnectUrl, amount, memo) {
  try {
    const response = await fetch('https://nostr-eggs-server-skphk.ondigitalocean.app/api/create_zap_invoice', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userPublicKey: userPublicKey,
        encryptedUserNostrWalletConnectUrl: encryptedUserNostrWalletConnectUrl,
        amount: amount,
        memo: memo
      }),
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error("Response Error: ", errorText);
      throw new Error(errorText || 'Failed to create invoice');
    }

    const data = await response.json();
    return data.invoice;

  } catch (error) {
    console.error("Error in createZapInvoice: ", error);
    // Rethrow the error for the caller to handle
    throw error;
  }
}



async function payZapInvoiceWithNwc(nostrWalletConnectUrl, invoice) {
  try {
    const response = await fetch('https://nostr-eggs-server-skphk.ondigitalocean.app/api/pay_zap_invoice_with_nwc', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        nostrWalletConnectUrl,
        invoice,
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    } else {
      // console.log("Successfully paid invoice")
    }

    const result = await response.json();
    return result.is_paid;
  } catch (error) {
    console.error('Error making payment:', error);
    throw error;
  }
}

function getNextRenewalDateISOString(renewalInterval) {
  const currentDate = new Date();

  switch (renewalInterval) {
    case "Yearly":
      currentDate.setFullYear(currentDate.getFullYear() + 1);
      break;
    case "Monthly":
      currentDate.setMonth(currentDate.getMonth() + 1);
      break;
    case "Weekly":
      currentDate.setDate(currentDate.getDate() + 7);
      break;
    case "Lifetime":
      currentDate.setFullYear(currentDate.getFullYear() + 100);
      break;
    default:
      throw new Error("Invalid renewal interval");
  }

  return currentDate.toISOString();
}

async function updateUserMembership(user_membership_id, userPublicKey, flashId, externalUuid, walletId, membershipId, isPaid, membershipStartDate, nextPaymentDate, status) {
  try {
    const response = await fetch('https://api.paywithflash.com/vimeo_ott/api/update_vimeo_ott_user_membership', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        user_membership_id: user_membership_id,
        user_public_key: userPublicKey,
        vimeo_ott_flash_id: flashId,
        external_uuid: externalUuid,
        wallet_id: walletId,
        vimeo_ott_membership_id: membershipId,
        is_paid: isPaid,
        membership_start_date: membershipStartDate,
        next_payment_date: nextPaymentDate,
        status: status
      })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error saving user membership:', error);
    throw error;
  }
}

// Function used to save the membership chosen by the user in table user_memberships
async function saveUserMembership(vimeo_ott_user_id, userPublicKey, flashId, walletId, membershipId, isPaid, membershipStartDate, nextPaymentDate, status) {

  try {
    const response = await fetch('https://api.paywithflash.com/vimeo_ott/api/save_vimeo_ott_user_membership', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        vimeo_ott_user_id: vimeo_ott_user_id,
        user_public_key: userPublicKey,
        vimeo_ott_flash_id: flashId,
        wallet_id: walletId,
        vimeo_ott_membership_id: membershipId,
        is_paid: isPaid,
        membership_start_date: membershipStartDate,
        next_payment_date: nextPaymentDate,
        status: status
      })
    });

    if (!response.ok) {
      // If the response status code is not in the 200-299 range,
      // throw an error with the status text
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    return result; // Handle the JSON response as needed
  } catch (error) {
    // Log the error to the console or handle it in another way
    console.error('Error saving user membership:', error);
    throw error;
  }
}


async function saveTransactionToDB(from_public_key, to_public_key, transactionDate, amountInSats, valueInUsd, flashId, flashName, flashType, toWalletId, toUserWalletName, fromWalletId, fromUserWalletName, lnurl) {
  const formData = new FormData();
  formData.append('from_public_key', from_public_key);
  formData.append('to_public_key', to_public_key);
  formData.append('transaction_date', transactionDate);
  formData.append('amount_in_sats', amountInSats);
  formData.append('value_in_usd', valueInUsd);
  formData.append('flash_id', flashId);
  formData.append('flash_name', flashName);
  formData.append('flash_type', flashType);
  formData.append('to_wallet_id', toWalletId !== null ? toWalletId : '');
  formData.append('to_user_wallet_name', toUserWalletName);
  formData.append('from_wallet_id', fromWalletId !== null ? fromWalletId : '');
  formData.append('from_user_wallet_name', fromUserWalletName);
  formData.append('lnurl', lnurl);

  try {
    const response = await fetch('https://api.paywithflash.com/api/save_new_transaction_to_db', {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to save transaction to database');
    }

    const successData = await response.json();
    // console.log('Transaction successfully saved to database:', successData);
    return successData;
  } catch (error) {
    console.error('Error saving transaction to database:', error);
    throw error;
  }
}




// Function to add a customer to Vimeo OTT
async function addCustomerToVimeoOTT(userEmail, userPassword, vimeoOttApiKey, vimeoOttProductId) {
  try {
    const authToken = btoa(`${vimeoOttApiKey}:`); // Base64 encode the API key for Basic Auth
    const requestBody = {
      email: userEmail,
      password: userPassword,
      product: `https://api.vhx.tv/products/${vimeoOttProductId}`
    };

    const response = await fetch('https://api.vhx.tv/customers', {
      method: 'POST',
      headers: {
        'Authorization': `Basic ${authToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestBody)
    });

    if (!response.ok) {
      const errorDetails = await response.json();
      console.error('Response Error Details:', errorDetails);
      throw new Error(`HTTP error! Status: ${response.status} - ${errorDetails.message}`);
    }

    const data = await response.json();
    // console.log('Customer added to Vimeo OTT:', data);
    return data;
  } catch (error) {
    console.error('Error adding customer to Vimeo OTT:', error.message);
    throw error;
  }
}


// Function to renew subscription for existing Vimeo OTT customer id
async function renewSubscriptionForCustomer(vimeoOttCustomerId, vimeoOttApiKey, vimeoOttProductId) {
  const authToken = btoa(`${vimeoOttApiKey}:`);
  try {
    const response = await fetch(`https://api.vhx.tv/customers/${vimeoOttCustomerId}/products`, {
      method: 'PUT',
      headers: {
        'Authorization': `Basic ${authToken}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        product: `https://api.vhx.tv/products/${vimeoOttProductId}`
      })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    // console.log('Product added to customer on Vimeo OTT:', data);
    return data;
  } catch (error) {
    console.error('Error adding product to customer on Vimeo OTT:', error);
    throw error;
  }
}



// The function used to for the user to pay the fee owed to referred him
async function payFeeToReferral(referralUserPublicKey, encryptedReferralUserNostrWalletConnectUrl, userPublicKey, encryptedUserNostrWalletConnectUrl, amount, memo, priceInSats, priceInDollars, flashId) {

  // Get transaction date
  const transactionDate = new Date().toISOString();

  // Get the price of bitcoin
  const bitcoinPrice = await getBitcoinPrice(); // If getBitcoinPrice is async, otherwise remove await

  // Call your server-side API to decrypt the config with nostr nip04
  const response = await fetch('https://nostr-eggs-server-skphk.ondigitalocean.app/api/pay_fee_to_referral', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      referralUserPublicKey: referralUserPublicKey,
      encryptedReferralUserNostrWalletConnectUrl: encryptedReferralUserNostrWalletConnectUrl,
      userPublicKey: userPublicKey,
      encryptedUserNostrWalletConnectUrl: encryptedUserNostrWalletConnectUrl,
      amount: amount,
      memo: memo,
      amount_in_sats: priceInSats,
      value_in_usd: priceInDollars,
      usd_btc_exchange_rate: bitcoinPrice,
      flash_id: flashId,
      transaction_date: transactionDate,
    }),
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || 'Failed to decrypt config');
  }

  return data;
}

async function payFeeToFlash(userPublicKey, encryptedUserNostrWalletConnectUrl, amount, memo, priceInSats, priceInDollars, flashId, user_public_key) {
  try {
    // Get transaction date
    const transactionDate = new Date().toISOString();

    // Get the price of bitcoin
    const bitcoinPrice = await getBitcoinPrice(); // If getBitcoinPrice is async, otherwise remove await

    // Call your server-side API to decrypt the config with nostr nip04
    const response = await fetch('https://nostr-eggs-server-skphk.ondigitalocean.app/api/pay_fee_to_flash', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userPublicKey: userPublicKey,
        encryptedUserNostrWalletConnectUrl: encryptedUserNostrWalletConnectUrl,
        amount: amount,
        memo: memo,
        amount_in_sats: priceInSats,
        value_in_usd: priceInDollars,
        usd_btc_exchange_rate: bitcoinPrice,
        flash_id: flashId,
        transaction_date: transactionDate,
        user_public_key: user_public_key
      }),
    });
    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || 'Failed to pay fee to flash');
    }
    // console.log('Fee transaction successfully saved to database:', data);
    return data;
  } catch (error) {
    console.error('Error in payFeeToFlash:', error);
    throw error; // Re-throw the error if you want the calling function to handle it
  }
}

const SubscriptionsPageVimeoOTT = () => {
  const flashPrivateKey = process.env.REACT_APP_NOSTR_PRIVATE_KEY
  const flashPublicKey = process.env.REACT_APP_NOSTR_PUBLIC_KEY

  const { productId } = useParams();

  const [customerId, setCustomerId] = useState(null);
  const [product, setProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [downloadLink, setDownloadLink] = useState('');
  const [orderDetails, setOrderDetails] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [priceInSats, setPriceInSats] = useState();
  const [priceInDollars, setPriceInDollars] = useState();
  const [priceInZloty, setPriceInZloty] = useState();

  const [appName, setAppName] = useState('');
  const [flashDetails, setFlashDetails] = useState('');
  const [flashUrl, setFlashUrl] = useState('') || "";
  const [flashId, setFlashId] = useState('');
  const [email, setEmail] = useState('');
  const [userPassword, setUserPassword] = useState('') || "";
  const [externalUuid, setExternalUuid] = useState('');
  const [sourceUrl, setSourceUrl] = useState('');
  const [userMemberships, setUserMemberships] = useState('');
  const [memberships, setMemberships] = useState([]);
  const [loginMethod, setLoginMethod] = useState('');

  const [isReferralExists, setIsReferralExists] = useState();
  const [referralData, setReferralData] = useState();
  const [referralWalletData, setReferralWalletData] = useState();

  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [userMembershipForFlashId, setUserMembershipForFlashId] = useState(null);
  const [selectedMembership, setSelectedMembership] = useState(null);
  const [isRenewal, setIsRenewal] = useState(null);

  const [userAlreadySubscribed, setUserAlreadySubscribed] = useState(false);
  const [walletNwcUrl, setWalletNwcUrl] = useState(null);
  const [flashWalletDetails, setFlashWalletDetails] = useState(null);
  const [payerPublicKey, setPayerPublicKey] = useState('');
  const [paymentSuccess, setPaymentSuccess] = useState('');
  const [transaction, setTransaction] = useState('');

  const handlePaymentSuccess = async (formValues, transaction) => {
    // Handle successful payment
    setPaymentSuccess(true);

    // Send the email and nostr DM
    if (formValues.email) {
      await sendSubscriptionPurchaseConfirmationEmail(flashDetails.name, selectedMembership, formValues.email, transaction.transaction_id)
    } else if (formValues.npub) {
      await sendSubscriptionPurchaseConfirmationNostrDM(flashDetails.name, selectedMembership, formValues.npub, transaction.transaction_id);
    }
  };

  const handleOrderSubmit = async (formValues) => {
    try {
      setIsProcessing(true); // Show the loading animation
      setErrorMessage(''); // Clear previous error messages
      setOrderDetails(formValues);

      let payerPublicKey;
      let walletId;
      let email;
      // If the user input an email address, then create a new user so that we have his npub
      if (formValues.email) {
        email = formValues.email
        setUserPassword(formValues.userPassword)
        // Check if email exists in the database
        let userPublicKey = await checkUserEmailAddressInDb(formValues.email);
        if (userPublicKey) {
          payerPublicKey = userPublicKey.userPublicKey;
        }
        else {
          // Create new user in db - return npub
          userPublicKey = await createNewUser(formValues.email, flashPublicKey);
          payerPublicKey = userPublicKey.userPublicKey;
        }

        // Create new wallet in the db
        walletId = await saveWalletToDB(appName, walletNwcUrl, payerPublicKey);
      } else if (formValues.npub) {
        payerPublicKey = await convertNostrToHex(formValues.npub);
        const publicKeyExists = await checkUserPublicKeyInDb(payerPublicKey);

        if (!publicKeyExists) {
          const data = await createNewUserWithPublicKey(payerPublicKey);
          if (!data.user_id) {
            throw new Error('An error in saving the new user with PublicKey');
          }
        }
        // Create new wallet in the db
        walletId = await saveWalletToDB(appName, walletNwcUrl, payerPublicKey);
      }

      setPayerPublicKey(payerPublicKey);

      // Query table to see if the user has an active membership for this flash_id. Use the user_public_key
      const user_memberships = await getUserMembershipsForFlashId(payerPublicKey, flashId);

      // Ensure there is at least one membership in the array
      if (user_memberships.length > 0) {
        const firstMembership = user_memberships[0]; // Take the first membership

        // Check the payment status of the first membership
        if (firstMembership.is_paid === 'false') {
          // console.log("Renewal - User subscribed but needs to pay");
          setIsRenewal(true);
        } else if (firstMembership.is_paid === 'true') {
          // console.log("Duplicate - User already subscribed and doesn't need to pay");
          setUserAlreadySubscribed(true);
          setIsProcessing(false); // Hide the loading animation
          return; // Return early if the user is already subscribed and has paid
        }
      } else {
        // console.log("No memberships found for this user and flash_id.");
        // Handle case where no memberships are found (perhaps guide user to subscription options)
      }

      // Create invoice with flash wallet nwc_url
      let invoice;
      try {
        invoice = await createZapInvoice(
          flashWalletDetails.publicKey,
          flashWalletDetails.nwc_url,
          selectedMembership.PriceInSatoshis,
          `Payment for ${selectedMembership.name} plan with ${appName}`
        );
      } catch (error) {
        setIsProcessing(false);
        setErrorMessage(`Failed to create invoice. Please try again later or contact ${appName}.`);
        console.error("Error creating invoice:", error);
        return; // Exit the function early if creating the invoice fails
      }

      // Pay invoice using the formValues.walletNwcUrl
      const is_paid = await payZapInvoiceWithNwc(formValues.walletNwcUrl, invoice)
      // Create new user_membership
      // If successful, save data to db
      if (is_paid) {

        // Get current date and next payment date
        const currentDate = new Date().toISOString();
        const nextRenewalDate = getNextRenewalDateISOString(selectedMembership.renewal_intervals);

        // If it is a renewal
        if (isRenewal) {
          // Add the plan to the user
          await renewSubscriptionForCustomer(userMembershipForFlashId.vimeo_ott_user_id, flashDetails.vimeo_ott_api_key, flashDetails.vimeo_ott_product_id)
          // Update the existing user_memberships row
          await updateUserMembership(userMembershipForFlashId.vimeo_ott_user_id, userMembershipForFlashId.id, payerPublicKey, flashId, walletId, selectedMembership.id, "true", currentDate, nextRenewalDate, "active")
        } else {
          // Add customer to Vimeo OTT
          const vimeoUserInfo = await addCustomerToVimeoOTT(email, userPassword, flashDetails.vimeo_ott_api_key, flashDetails.vimeo_ott_product_id);
          // Add line to table user_memberships
          await saveUserMembership(vimeoUserInfo.id, payerPublicKey, flashId, walletId, selectedMembership.id, "true", currentDate, nextRenewalDate, "active")
        }

        // Also add the transaction to table transactions
        const transaction = await saveTransactionToDB(payerPublicKey, flashDetails.user_public_key, currentDate, selectedMembership.PriceInSatoshis, selectedMembership.PriceInDollars, flashId, flashDetails.name, "Vimeo OTT Subscription Plan", flashWalletDetails.id, flashWalletDetails.user_wallet_name, walletId, appName, invoice)
        setTransaction(transaction);

        // Call handlePaymentSuccess to show the success screen and send confirmation email/DM
        handlePaymentSuccess(formValues, transaction)


        // Calculate the fee and price minus fee
        const feePercentage = 1.5; // 1%

        // Assuming priceInSats is already defined and is the total price in Satoshis
        let satsFee = selectedMembership.PriceInSatoshis * (feePercentage / 100); // Calculate the fee
        satsFee = Math.round(satsFee); // Rounding to the nearest whole number
        let satsFeeToFlash, satsFeeToReferral;
        if (isReferralExists) {
          satsFeeToFlash = satsFee * 70 / 100;
          satsFeeToReferral = satsFee * 30 / 100;
          await payFeeToReferral(referralData.user_public_key, referralWalletData.nwc_url, flashDetails.user_public_key, flashWalletDetails.nwc_url, satsFeeToReferral, `Referral Fee for flash id: ${flashId}`, selectedMembership.PriceInSatoshis, selectedMembership.PriceInDollars, flashId, flashDetails.user_public_key);
        }
        else satsFeeToFlash = satsFee;

        await payFeeToFlash(flashDetails.user_public_key, flashWalletDetails.nwc_url, satsFeeToFlash, `Fee for flash id: ${flashId}`, selectedMembership.PriceInSatoshis, selectedMembership.PriceInDollars, flashId, flashDetails.user_public_key);

      }
    } catch (error) {
      setIsProcessing(false);
      setErrorMessage("There was an error processing the payment. Check your wallet and try again later.");
      console.error("Error during payment:", error);
    } finally {
      setIsProcessing(false); // Hide the loading animation
    }
  }


  const handleSelectedMembershipChange = (membership) => {
    setSelectedMembership(membership);
  };


  useEffect(() => {
    // Extracts URL parameters, useful for both redirects and initial iframe load
    const queryParams = new URLSearchParams(window.location.search);
    const flashId = queryParams.get('flashId');
    const email = queryParams.get('email');
    const externalUuid = queryParams.get('external_uuid');

    setFlashId(flashId);
    setEmail(email);
    setExternalUuid(externalUuid);

    // Uses document.referrer to set the source URL; useful for both iframe and redirects
    setSourceUrl(document.referrer);

  }, []);

  useEffect(() => {
    if (externalUuid) {
      getUserMembershipsForFlashId('', flashId)
        .then(async (data) => {
          if (data.status) {
            const membership_id = data.userMembership[0].membership_id;
            const user_public_key = data.userMembership[0].user_public_key;
            const membership = await getMembershipById(membership_id);
            setSelectedMembership(membership);
            setPayerPublicKey(user_public_key);
            setUserAlreadySubscribed(true);
            return;
          }
        })
    }
    else if (email && !externalUuid) {
      checkUserEmailAddressInDb(email)
        .then(async (data) => {
          if (data) {
            const user_public_key = data;
            setPayerPublicKey(user_public_key);
            const user_memberships = await getUserMembershipsForFlashId(user_public_key, flashId);

            // Ensure there is at least one membership in the array
            if (user_memberships.length > 0) {
              const firstMembership = user_memberships[0]; // Take the first membership

              if (firstMembership.is_paid === 'true') {
                // console.log("Duplicate - User already subscribed and doesn't need to pay");
                const membership_id = firstMembership.membership_id;
                const membership = await getMembershipById(membership_id);
                setSelectedMembership(membership);
                setUserAlreadySubscribed(true);
                return; // Return early if the user is already subscribed and has paid
              }
            } else {
              // console.log("No memberships found for this user and flash_id.");
              // Handle case where no memberships are found (perhaps guide user to subscription options)
            }
          }
          else {
            setUserAlreadySubscribed(false);
          }
        })
    }

    if (flashId) {
      setIsLoading(true); // Set loading true at the start of the effect

      const fetchFlashDetails = async () => {
        try {
          const flash = await getFlashById(flashId);
          if (flash) {
            setFlashDetails(flash);
            setPriceInDollars(flash.PriceInDollars);
            setPriceInSats(flash.PriceInSatoshis);
            setAppName(flash.name || ''); // Handle potentially undefined properties
            setFlashUrl(flash.url || '');
            setLoginMethod(flash.flash_login_method || '');
          } else {
            // Handle case where no flash details are returned
            setFlashDetails({});
            setPriceInDollars(0);
            setPriceInSats(0);
            setAppName('');
            setFlashUrl('');
            setLoginMethod('');
          }
        } catch (error) {
          console.error('Failed to load flash details:', error);
          setFlashDetails({});
        }

        try {
          const memberships = await getMembershipsByFlashId(flashId);
          setMemberships(memberships || []);
        } catch (error) {
          console.error('Failed to load memberships:', error);
          setMemberships([]);
        }

        setIsLoading(false); // Set loading false after all fetches have been attempted
      };

      fetchFlashDetails();
    }
  }, [flashId, email, externalUuid])


  useEffect(() => {
    if (orderDetails) {
      getUserMembershipsForFlashId(orderDetails.npub, flashId)
        .then(userMemberships => {
          if (userMemberships) {
            setUserMemberships(userMemberships)
          }
          setIsLoading(false);
        });
    }
  }, [orderDetails]);

  // Get the flash wallet details
  useEffect(() => {

    const fetchReferralData = async () => {

      const referralData = await fetchReferralDataWithPublicKey(flashDetails.user_public_key);
      setIsReferralExists(referralData.isReferralExists);
      setReferralData(referralData.referralData);

      if (referralData.isReferralExists) {
        const referralWalletData = await getWalletFromId(referralData.referralData.wallet_id);
        setReferralWalletData(referralWalletData);
      }
    }

    const fetchWalletDetails = async () => {
      if (flashDetails) {
        const flashWalletId = flashDetails.wallet_id;

        // Get the details of the wallet from the db and set to state variable
        try {
          const flashWalletDetails = await getWalletFromId(flashWalletId);
          setFlashWalletDetails(flashWalletDetails);
        } catch (error) {
          console.error('Error fetching wallet details:', error);
          // Handle the error as needed
        }
      }
    };

    fetchReferralData();
    fetchWalletDetails();
  }, [flashDetails]);


  if (error) return <div>Error: {error}</div>;

  return (
    <>
      {(isLoading || isProcessing) && (
        <div className="loading-container absolute inset-0 flex items-center justify-center bg-black bg-opacity-60" style={{ zIndex: '100' }}>
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-800 h-12 w-12 mb-4"></div>
        </div>
      )}
      <div className="flex justify-center items-center lg:p-32">
        <div className="w-full bg-white rounded-xl overflow-hidden">
          <div className="grid grid-cols-2 gap-2 lg:divide-x-2">
            <div className="lg:col-span-1 sm:col-span-2 col-span-2 p-8 flex flex-col justify-center items-center">
              <div className="px-8 pt-8 w-full flex items-start">
                <div className="uppercase tracking-wide font-semibold text-gray-500">{flashDetails.name} </div>
              </div>
              <div className="flex justify-center items-center md:flex-shrink-0 p-8 pb-3 w-3/5">
                <img
                  className="object-contain rounded-xl"
                  src={flashDetails.flash_image_url}
                  alt="First Image"
                />
              </div>
              <div className="text-center text-xs text-gray-500 mt-8"> Powered by <b>Flash</b> </div>
            </div>
            <div className="lg:col-span-1 sm:col-span-2 col-span-2 flex flex-col justify-center">
              {!userAlreadySubscribed &&
                <>
                  <ProductOrderFormVimeoOTT
                    onOrderSubmitted={handleOrderSubmit}
                    walletNwcUrl={walletNwcUrl}
                    setWalletNwcUrl={setWalletNwcUrl}
                    memberships={memberships}
                    onSelectedMembershipChange={handleSelectedMembershipChange}
                    eMail={email}
                    userPassword={userPassword}
                    setUserPassword={setUserPassword}
                    loginMethod={loginMethod}
                    appName={appName}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                  />
                </>
              }
              {userAlreadySubscribed && (
                <UserAlreadySubscribedScreen payerPublicKey={payerPublicKey} selectedMembership={selectedMembership} />
              )}
              {paymentSuccess && (
                <PaymentSuccessScreen selectedMembership={selectedMembership} orderDetails={orderDetails} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscriptionsPageVimeoOTT;