import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SidebarLayout from '../components/SidebarLayout';
import HomeStats from '../components/MyIncome/IncomeTables/HomeStats';
import ChartHome from '../components/MyIncome/IncomeTables/ChartHome';
import WalletList from '../components/DashboardComponents/WalletList';
import RecentTransactions from '../components/DashboardComponents/RecentTransactions';
import Header from '../components/FlashStatic/Header';
import PayFromQRCodeReader from '../components/payFromQRCode/PayFromQRCodeReader';
import TourModal from '../components/DashboardComponents/TourModal'; // Import the TourModal component

function Dashboard() {
  const location = useLocation();
  let userHexPublicKey = localStorage.getItem('userHexPublicKey') || "";
  const [transactionsData, setTransactionsData] = useState([]);
  const [isQRCodeButtonActive, setIsQRCodeButtonActive] = useState(false);
  const [showQRReader, setShowQRReader] = useState(false);
  const [interfaceMode, setInterfaceMode] = useState(localStorage.getItem('interfaceMode'));
  const [isTourModalOpen, setIsTourModalOpen] = useState(false); // State to handle modal visibility
  const navigate = useNavigate();

  useEffect(() => {
    fetchTransactionsWithDetails(userHexPublicKey)
      .then(data => {
        if (data && data.transactions) {
          setTransactionsData(data.transactions);
        }
      });
  }, [userHexPublicKey]);

  useEffect(() => {
    // Dynamically load Tawk.to chat script
    var Tawk_API = Tawk_API || {};
    var Tawk_LoadStart = new Date();
    (function() {
      var s1 = document.createElement("script"),
          s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/66c71484ea492f34bc08e27c/1i5sqga3q';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
    })();
  }, []); // Empty dependency array to ensure this runs only once on mount

  useEffect(() => {
    // Use a user-specific key to track if the user has seen the tour
    const userTourKey = `hasVisitedDashboard_${userHexPublicKey}`;

    // Check if it's the user's first visit
    const hasVisited = localStorage.getItem(userTourKey);
    if (!hasVisited && userHexPublicKey) {
      // Show the TourModal
      setIsTourModalOpen(true);
      // Set a flag to indicate that the user has visited the dashboard
      localStorage.setItem(userTourKey, 'true');
    }
  }, [userHexPublicKey]); // This will run when userHexPublicKey is available

  async function fetchTransactionsWithDetails(userPublicKey) {
    try {
      const response = await fetch(`https://api.paywithflash.com/api/get_incoming_new_transactions_by_user?user_public_key=${encodeURIComponent(userPublicKey)}`);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching transactions:', error);
    }
  }

  const toggleContentVisibility = () => {
    setIsQRCodeButtonActive(!isQRCodeButtonActive);
  };

  return (
    <div>
      {isQRCodeButtonActive && (
        <div className="p-8 bg-white rounded-xl ring-indigo-100 shadow-md shadow-gray-200">
          <div style={{ width: '100%', height: 'auto', minHeight: '300px' }}>
            <PayFromQRCodeReader
              toggleContentVisibility={toggleContentVisibility}
              showQRReader={showQRReader}
              setShowQRReader={setShowQRReader}
            />
          </div>
        </div>
      )}
      <SidebarLayout interfaceMode={interfaceMode} setInterfaceMode={setInterfaceMode}>
        {!isQRCodeButtonActive && (
          <>
            <Header
              title="Dashboard"
              subtitle="Welcome to Flash, the new BTC payment gateway."
              showWalletsAvatar={false}
              id="header"
            />
            <div className='p-3 md:p-5' id="main-content">
              <div className="flex flex-col md:flex-row w-full">
                <div className="md:w-2/3 bg-white rounded-lg ring-indigo-100 shadow-sm shadow-gray-200">
                  <img src='/nimages/app-cover3.png' className='cover mx-auto w-full h-20 object-cover rounded-t-lg'></img>
                  <div className="flex flex-col px-6 mt-2 ">
                    <h3 className="text-2xl mb-1 font-bold text-slate-800">Flash, the Bitcoin payment gateway.</h3>
                    <p className="mb-2 text-slate-600 opacity-80 -mb-2">The fastest way to accept payments in Bitcoin, via the Lightning network.</p>
                    <div className="lg:hidden">
                      <button className="w-full items-center gap-x-1.5 py-4 text-md rounded-md bg-slate-800 px-3 mt-2 mb-3 font-semibold text-white shadow-sm hover:bg-slate-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={() => {
                          toggleContentVisibility();
                          navigate('/point-of-sale');
                        }}>
                        Launch POS
                      </button>
                      <button className="w-full flex flex-row justify-center text-center items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-4 text-md font-regular text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={() => {
                          setShowQRReader(true);
                          toggleContentVisibility();
                        }}>
                        Pay with connected wallets (beta)
                      </button>
                    </div>
                  </div>
                </div>
                <div className="mt-4 md:mt-0 ml-0 md:ml-4 md:w-1/3 bg-white p-4 px-6 rounded-xl ring-indigo-100 shadow-sm shadow-gray-200" id="wallet-list">
                  <WalletList />
                </div>
              </div>
              <div className='flex flex-col md:flex-row w-full'>
                <div className='w-full md:w-2/3 pr-0 md:pr-2 mt-3'>
                  <div className='card bg-white p-6 shadow-sm rounded-md pb-0' id="home-stats">
                    <h2 className='text-lg font-semibold text-slate-900'>This week</h2>
                    <p className='text-sm font-regular text-slate-500'>Your sales per day, in Sats and Dollars.</p>
                    <HomeStats initialData={transactionsData}
                      columnsToShow={['id', 'lnurl', 'amount_in_sats', 'value_in_usd', 'flash_name', 'transaction_date']}
                    />
                    <ChartHome initialData={transactionsData} />
                  </div>
                </div>
                <div className="w-full md:w-1/3 mt-3 mx-auto bg-white p-6 rounded-xl ring-indigo-100 shadow-sm ml-0 md:ml-2 shadow-gray-200" id="recent-transactions">
                  <RecentTransactions />
                </div>
              </div>
            </div>
          </>
        )}
      </SidebarLayout>

      {/* Add the TourModal component */}
      <TourModal isOpen={isTourModalOpen} setIsOpen={setIsTourModalOpen} />
    </div>
  );
}

export default Dashboard;
