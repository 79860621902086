import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import WalletsAvatars from './WalletsAvatars';
import WalletDropdown from '../Navbar/WalletsDropdown';
import WalletsTotal from '../Navbar/WalletsTotal';
import TourModal from '../DashboardComponents/TourModal';
import { ArrowRightCircleIcon } from '@heroicons/react/24/solid';
import { getAndUpdateWalletList } from '../../Redux/Actions/walletAction';

const ProfileComponent = () => {
  let userHexPrivateKey = localStorage.getItem('userHexPrivateKey') || "";
  let userHexPublicKey = localStorage.getItem('userHexPublicKey') || "";
  let userNpub = localStorage.getItem('userNpub') || "";
  let userName = localStorage.getItem('userName') || "";
  let userAbout = localStorage.getItem('userAbout') || "";
  let userPictureUrl = localStorage.getItem('userPictureUrl') || "";

  return (
    <a href="/my-account" className="group block flex-shrink-0">
      <div className="btn btn-primary hover:bg-slate-100 animate-none hover:border-slate-200 bg-white text-slate-800  border-slate-200 px-2 pl-3 justify-center align-middle  min-h-0 h-fit py-1">
        <img
          className="inline-block h-6 w-6 rounded-full object-cover  "
          src={userPictureUrl}
          alt={userName !== "" ? userName : userNpub.slice(0, 10) + "..."}
        />
        <div className=" ">

          <p className="text-sm font-semibold text-slate-900 group-hover:text-gray-900">{userName !== "" ? userName : userNpub.slice(0, 10) + "..."}</p>
        </div>
        <div className="flex-grow"></div>
      </div>
    </a>
  );
};

export default function Header({
  title,
  subtitle,
  buttonText1,
  buttonLink1,
  buttonText2,
  buttonLink2,
  badgeText,
  showBadgeText = false,
  badgeLink,
  showWalletsAvatar = false
}) {
  const privateKey = process.env.REACT_APP_NOSTR_PRIVATE_KEY;
  const publicKey = process.env.REACT_APP_NOSTR_PUBLIC_KEY;

  const userHexPrivateKey = localStorage.getItem('userHexPrivateKey') || "";
  const userHexPublicKey = localStorage.getItem('userHexPublicKey') || "";
  const userNpub = localStorage.getItem('userNpub') || "";
  const userName = localStorage.getItem('userName') || "";
  const userAbout = localStorage.getItem('userAbout') || "";
  const userPictureUrl = localStorage.getItem('userPictureUrl') || "";

  const [totalBalance, setTotalBalance] = useState(0);
  const [totalBalanceUSD, setTotalBalanceUSD] = useState(0);
  const [isTourModalOpen, setIsTourModalOpen] = useState(false);
  const [isPulsating, setIsPulsating] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsPulsating(true);
      setTimeout(() => {
        setIsPulsating(false);
      }, 1000); // Pulsate for 1 second (covers two pulses)
    }, 10000); // Trigger pulsation every 10 seconds

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    getAndUpdateWalletList(userHexPrivateKey, publicKey);
  }, []);

  const wallets = useSelector((state) => state.walletReducer.wallets);

  useEffect(() => {
    const convert = async () => {
      const totalUSD = await convertPrices("usd", totalInSatoshis);
      setTotalBalanceUSD(parseFloat(totalUSD));
    };

    const totalInMilliSatoshis = wallets.reduce((acc, wallet) => acc + parseFloat(wallet.balance), 0);
    const totalInSatoshis = totalInMilliSatoshis / 1000;

    setTotalBalance(totalInSatoshis);
    convert();
  }, [wallets]);

  async function getBitcoinPrice() {
    try {
      const response = await fetch('https://api.paywithflash.com/api/get_bitcoin_price', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`API call failed with status: ${response.status}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('API call failed:', error);
      throw error;
    }
  }

  async function convertPrices(currency, value) {
    try {
      const bitcoinPrice = await getBitcoinPrice();

      let convertedPrice;

      if (currency === "usd") {
        const priceInDollars = value / 100000000;
        convertedPrice = (priceInDollars * bitcoinPrice).toFixed(2);
      } else if (currency === "satoshis") {
        convertedPrice = value;
      }
      return convertedPrice;
    } catch (error) {
      console.error('Conversion failed:', error);
      return null;
    }
  }

  return (
    <div className="flex items-top pt-20 -mt-2 md:-mt-0 z-50 md:pt-3 justify-between px-2 sm:px-3 lg:px-4 account-header p-3 bg-white pb-3 border-slate-300">
      {/* Left side content */}
      <div className="min-w-0 flex-1 md:mt-0 ml-2 md:ml-0">
        <h2 className="text-lg pt-1 pl-1.5 font-semibold text-slate-900 sm:truncate sm:text-xl tracking-normal">
          {title || 'Insert title here...'}
        </h2>
        {badgeText && (
          <a
            href={badgeLink || '#'}
            className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
          >
            {badgeText}
          </a>
        )}
        {showWalletsAvatar && <WalletsAvatars />}
      </div>

      {/* Right side - Profile Component */}
      <div className="flex justify-end flex-row w-3/4 gap-2">
        <div className="hidden md:flex gap-2">
          <div
            className={`btn flex btn-primary border-purple-600 hover:bg-slate-100 ${isPulsating ? 'pulsating' : 'pulse-trigger'} bg-white text-slate-800 py-1.5 border-slate-200 px-3 min-h-0 h-fit`}
            onClick={() => setIsTourModalOpen(true)}
          >
            <div className="flex">
              <button
                onClick={() => setIsTourModalOpen(true)}
                className="flex items-center text-sm font-semibold text-gray-800 hover:text-gray-600"
              >
                <span aria-hidden="true">New here? Take the tour</span>
              </button>
            </div>
          </div>
          <WalletsTotal totalBalance={totalBalance} totalBalanceUSD={totalBalanceUSD} />
          <WalletDropdown wallets={wallets} />
        </div>
        <ProfileComponent />
      </div>
      <TourModal isOpen={isTourModalOpen} setIsOpen={setIsTourModalOpen} />
    </div>
  );
}
